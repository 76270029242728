body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebeff2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-green {
  @apply bg-lightGreen px-4 py-2 text-white rounded-sm hover:bg-lightGreen-dark focus:ring-1 ring-lightGreen-dark;
}

.btn-parrot {
  @apply bg-green-500 px-4 py-2 text-white rounded-sm hover:bg-green-600 focus:ring-1 ring-green-500;
}

.btn-red {
  @apply bg-red-500 px-4 py-2 text-white rounded-sm hover:bg-red-600 focus:ring-1 ring-red-500;
}

.btn-sm-blue {
  @apply bg-lightBlue px-2 py-2 text-white rounded-sm hover:bg-lightBlue-dark focus:ring-1 ring-lightBlue-dark;
}

.btn-sm-yellow {
  @apply bg-yellow-400 px-2 py-2 text-white rounded-sm hover:bg-yellow-500 focus:ring-1 ring-yellow-500;
}

.btn-sm-green {
  @apply bg-lightGreen px-2 py-2 text-white rounded-sm hover:bg-lightGreen-dark focus:ring-1 ring-lightGreen-dark;
}

.btn-sm-gray {
  @apply bg-gray-300 px-2 py-2 text-gray-500 rounded-sm hover:bg-gray-200 focus:ring-1 ring-gray-300;
}

.btn-sm-red {
  @apply bg-red-500 px-2 py-2 text-white rounded-sm hover:bg-red-600 focus:ring-1 ring-red-500;
}

.btn-blue {
  @apply bg-lightBlue px-4 py-2 text-white rounded-sm hover:bg-lightBlue-dark focus:ring-1 ring-lightBlue-dark;
}

.btn-gray {
  @apply bg-gray-300 px-4 py-2 text-gray-500 rounded-sm hover:bg-gray-200 focus:ring-1 ring-gray-200;
}

.input-field {
  @apply border border-gray-300 p-2  rounded-sm focus:outline-none text-gray-500;
}

.input-select {
  @apply p-2 border border-gray-300 bg-white rounded-sm focus:outline-none text-gray-500;
}

.order-table {
  th,
  td {
    @apply px-5 text-center py-4 text-gray-500;
  }
}
.order-table {
  tr {
    @apply border-b-2;
  }
}

.btn-outline {
  @apply border border-gray-300 p-2 text-gray-500;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 1em;
  width: 1em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.3), rgba(0, 0, 0, 0.3));
}
